import React from "react";
import { Link } from "react-router-dom";

function navbar() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <Link className="navbar-brand" to="/">
            Altecho
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  Contact Us
                </Link>
              </li>
              {/* <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="/"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Products
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <Link className="dropdown-item" to="/">
                      Dolomite Powder
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/">
                      Dolomite Grains
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/products/quartz-powder">
                      Quartz Powder
                    </Link>
                  </li>
                  
                </ul>
              </li> */}
              {/* Products Dropdown */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="productsDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Products
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="productsDropdown"
                >
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/products/dolomitePowder"
                    >
                      Dolomite Powder
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/products/dolomiteGrains"
                    >
                      Dolomite Grains
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/products/quartzPowder">
                      Quartz Powder
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/products/quartzGrains">
                      Quartz Grains
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/products/talcPowder">
                      Talc Powder
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/products/silicaRammingMass"
                    >
                      Ramming Mass
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/products/calcitePowder"
                    >
                      Calcite Powder
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/products/barytesPowder"
                    >
                      Barytes Powder
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/products/bentonitePowder"
                    >
                      Bentonite Powder
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/products/micaProducts">
                      Mica Flakes Powder
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/products/feldsparPowder"
                    >
                      Feldspar
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/products/garnetAbrasiveSand"
                    >
                      Garnet / Abrasive Sand
                    </Link>
                  </li>
                </ul>
              </li>

              {/* Chemicals Dropdown */}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="chemicalsDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Chemicals
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="chemicalsDropdown"
                >
                  <li>
                    <Link className="dropdown-item" to="/chemicals/zincOxide">
                      Zinc Oxide
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/chemicals/zincStearate"
                    >
                      Zinc Stearate
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/chemicals/calciumStearate"
                    >
                      Calcium Stearate
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/chemicals/aluminiumStearate"
                    >
                      Aluminium Stearate
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/chemicals/ferrousSulphate"
                    >
                      Ferrous Sulphate
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/chemicals/zincSulphate"
                    >
                      Zinc Sulphate
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/chemicals/magnesiumStearate"
                    >
                      Magnesium Stearate
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/chemicals/calciumZincStabilizer"
                    >
                      Calcium Zinc Stabilizer
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/chemicals/potassiumStearate"
                    >
                      Potassium Stearate
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/chemicals/sodiumStearate"
                    >
                      Sodium Stearate
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/chemicals/sodiumBenzoate"
                    >
                      Sodium Benzoate
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default navbar;
