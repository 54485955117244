import React from "react";
import { useParams } from "react-router-dom";

const products = {
  garnetAbrasiveSand: {
    name: "Garnet / Abrasive Sand",
    chemicalComponent: "Fe2O3, SiO2, Al2O3, CaO",
    description:
      "Garnet is a high-quality industrial abrasive material recognized for its sharpness, durability, and versatility. Available in various grades and mesh sizes, it is designed to meet the rigorous demands of industries globally.",
    applications:
      "Used in abrasive blasting, water jet cutting systems, oil drilling, water filtration, denim washing, anti-skid flooring, paint removal, and oil extraction.",
    packagingOptions:
      "Available in 25 kg bags, 50 kg bags, and 1-ton jumbo bags, with customized packaging options upon request.",
    other:
      "Alteco's Garnet is a reliable choice, meeting high industry standards and ensuring consistent quality across diverse sectors.",
  },

  feldsparPowder: {
    name: "Feldspar Powder",
    chemicalComponent: "SiO2 (66-72%), Al2O3 (19-21%), K2O/Na2O",
    description:
      "Feldspar Powder is valued for its high whiteness and strength, extracted from Rajasthan's Aravali Valley, making it ideal for various industrial applications.",
    applications:
      "Enhances the durability of ceramic tiles, glass, and ceramic crockery. It is also used in paints, rubber production, fertilizers, and cattle feed.",
    packagingOptions:
      "Available in 25 kg, 50 kg, and 1-ton jumbo bags, with both printed and unprinted PP woven sacks, along with customizable packaging options.",
    other:
      "Known for its purity and quality, Alteco's Feldspar Powder is trusted in ceramics, glass, and construction industries.",
  },

  micaProducts: {
    name: "Mica Powder and Mica Flakes",
    chemicalComponent: "SiO2 (45%), Al2O3 (33.25%), K2O (10%)",
    description:
      "Mica Powder and Flakes are recognized for their insulating properties, durability, and crystal-clear appearance, offering high-quality performance in various industries.",
    applications:
      "Used in paints, electronics, welding electrodes, rubber production, thermal insulation, and oil and gas drilling fluids.",
    packagingOptions:
      "Available in 25 kg bags, 50 kg bags, and 1-ton jumbo bags, with both printed and plain PP woven sacks, and customized packaging options.",
    other:
      "Alteco’s mica products meet stringent quality standards, ensuring reliability in diverse industrial applications.",
  },

  bentonitePowder: {
    name: "Bentonite Powder",
    chemicalComponent: "SiO2 (45%), Al2O3 (33.25%)",
    description:
      "Bentonite Powder is a naturally occurring clay known for its water-absorbent properties and high plasticity, widely used in various industries.",
    applications:
      "Used in foundries, oil drilling, cement, agriculture, animal feed, and cosmetics, enhancing soil quality and promoting plant growth.",
    packagingOptions:
      "Available in 25 kg, 50 kg bags, and 1-ton jumbo bags, with customizable packaging solutions.",
    other:
      "Alteco’s Bentonite meets industry standards, ensuring high performance and reliability across diverse applications.",
  },

  barytesPowder: {
    name: "Barytes Powder",
    chemicalComponent: "BaSO4 (90-95%)",
    description:
      "Barytes Powder is a dense mineral, high in barium sulfate, offering high brightness and opacity for various industrial uses.",
    applications:
      "Used in paint manufacturing, rubber production, coatings, oil drilling, and inks, enhancing product density and quality.",
    packagingOptions:
      "Available in 25 kg bags, 50 kg bags, and 1-ton jumbo bags, with options for printed and liner bags based on customer requirements.",
    other:
      "Alteco's Barytes Powder ensures consistent quality through stringent quality control.",
  },

  calcitePowder: {
    name: "Calcite Powder / Calcium Carbonate",
    chemicalComponent: "CaCO3 (92-99%)",
    description:
      "Calcite Powder is valued for its high CaCO3 content and versatility, enhancing durability and smoothness in various applications.",
    applications:
      "Used in construction chemicals, paints, plastics, soaps, detergents, toothpaste, and ceramics for gloss and strength.",
    packagingOptions:
      "Available in 25 kg, 50 kg bags, and 1-ton jumbo bags, with flexible packaging to meet specific needs.",
    other:
      "Produced to the highest standards, Alteco's Calcite Powder is trusted for purity and performance across multiple industries.",
  },

  silicaRammingMass: {
    name: "Silica Ramming Mass",
    chemicalComponent: "SiO2",
    description:
      "Silica Ramming Mass, made from high-purity quartzite, is designed for use in induction furnace linings, providing exceptional performance under extreme temperatures.",
    applications:
      "Primarily used in steel and iron industries to maintain induction furnaces, ensuring high resistance to thermal expansion and corrosion.",
    packagingOptions:
      "Available in 25 kg bags, 50 kg bags, and 1-ton jumbo bags, with custom packaging options available.",
    other:
      "Alteco's Silica Ramming Mass is known for its durability and minimal wear, ideal for heavy-duty furnace applications.",
  },

  dolomiteGrains: {
    name: "Dolomite Grains",
    chemicalComponent: "CaMg(CO₃)₂",
    description:
      "Dolomite Grains are high-whiteness grains, known for their smooth texture and softness, providing reliable performance in various industries.",
    applications:
      "Used in paint, plastic, and fertilizer industries, enhancing smoothness and improving soil quality.",
    packagingOptions:
      "Available in 50 kg bags, ensuring quality preservation during transport.",
    other:
      "Alteco’s Dolomite Grains provide consistent performance across multiple sectors, meeting quality standards.",
  },

  talcPowder: {
    name: "Talc Powder",
    chemicalComponent: "SiO₂, MgO",
    description:
      "Talc Powder is known for its fine texture and softness, providing smoothness and durability for various industrial applications.",
    applications:
      "Used in paints, plastics, cosmetics, paper, and ceramics for enhanced texture and smoothness.",
    packagingOptions:
      "Available in 25 kg, 50 kg, and 1-ton jumbo bags, with options for branded or plain packaging.",
    other:
      "Produced to meet specific high-performance needs, Alteco’s Talc Powder ensures quality and reliability.",
  },

  quartzGrains: {
    name: "Quartz Grains",
    chemicalComponent: "SiO₂ (up to 99%)",
    description:
      "Quartz Grains are high-purity grains known for their hardness and white color, offering exceptional durability for industrial applications.",
    applications:
      "Used in glass manufacturing, artificial marble, ceramic tiles, and abrasives for high durability and gloss.",
    packagingOptions:
      "Available in 25 kg, 50 kg bags, and 1-ton jumbo bags, with custom options for specific industry needs.",
    other:
      "Alteco’s Quartz Grains are carefully processed to meet the highest quality standards for reliable performance.",
  },

  quartzPowder: {
    name: "Quartz Powder",
    chemicalComponent: "SiO₂ (up to 99.99%)",
    description:
      "Quartz Powder is appreciated for its high silica content and pure white appearance, ideal for demanding industrial applications.",
    applications:
      "Used in glass, ceramics, artificial stone, and silica brick manufacturing, providing durability and purity.",
    packagingOptions:
      "Available in 25 kg, 50 kg bags, and 1-ton jumbo bags, with tailored packaging solutions.",
    other:
      "Alteco’s Quartz Powder ensures optimal performance in all applications with its high-quality standards.",
  },

  dolomitePowder: {
    name: "Dolomite Powder",
    chemicalComponent: "CaCO₃, MgCO₃",
    description:
      "Dolomite Powder is a high-quality product known for its high whiteness and performance in various applications.",
    applications:
      "Used in soap manufacturing, ceramics, agriculture, and more, enhancing texture and soil quality.",
    packagingOptions:
      "Available in 25 kg, 50 kg, and 1-ton jumbo bags, with flexible packaging to ensure safe delivery and easy handling.",
    other:
      "Alteco’s Dolomite Powder ensures consistent quality and is trusted by industries worldwide.",
  },

  // Add more product data here
};

function ProductDetailsPage() {
  const { productId } = useParams();
  const product = products[productId];

  if (!product) {
    return <p>Product not found.</p>;
  }

  return (
    // <div className="container">
    //   <div className="row product-details">
    //     <div className="col-lg-6 col-md-6 col-sm-12">
    //       <h1 className="title">{product.name}</h1>
    //       <p className="description">{product.description}</p>
    //     </div>
    //     <div className="col-lg-6 col-md-6 col-sm-12 img-container"></div>

    //     <h3 className="subtitle">Applications:</h3>
    //     <ul className="subtitle-data">
    //       {product.applications.map((app, idx) => (
    //         <li key={idx}>{app}</li>
    //       ))}
    //     </ul>
    //     <h3 className="subtitle">Packaging Options:</h3>
    //     <ul className="subtitle-data">
    //       {product.packagingOptions.map((option, idx) => (
    //         <li key={idx}>{option}</li>
    //       ))}
    //     </ul>
    //   </div>
    // </div>

    <div className="container">
      <div className="row product-details">
        <h1 className="title">{product.name}</h1>
        <p>{product.description}</p>
        <h4 className="subtitle">Component:</h4>
        <p>{product.chemicalComponent}</p>
        <h4 className="subtitle">Applications:</h4>
        <p>{product.applications}</p>
        <h4 className="subtitle">Packaging Options:</h4>
        <p>{product.packagingOptions}</p>
        <p>{product.other}</p>
      </div>
    </div>
  );
}

export default ProductDetailsPage;
