import React from "react";
import story from "../assets/imgs/Factory-Vector-PNG-Pic-HQ.png";
import detail from "../assets/imgs/coal-mining-industry-png.webp";
import vision from "../assets/imgs/business-team-vision-5531616-4621137.webp";

const about = () => {
  return (
    <div>
      {/* about */}
      <section id="about-cont">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="about-cont-head">About Us</h2>
            </div>
          </div>
        </div>
      </section>
      {/* about-end */}

      {/* about-details  */}
      <section id="about-details">
        <div className="container">
          <div className="row">
            <p className="center">
              We pride ourselves on being the premier supplier and exporter of
              Indian raw minerals and chemicals. Our dedicated team comprises
              experienced professionals who have been with us since inception,
              along with skilled workers committed to our growth and success.
            </p>

            <div className="col-lg-6 col-md-6 col-sm-12 para">
              <h4 className="head">Our Story</h4>
              <p>
                Altecho Ventures Pvt. Ltd. began its journey in 2010 with the
                establishment of a manufacturing facility in Udaipur, Rajasthan,
                India. We initially specialized in dolomite powder production,
                expanding our operations to include our own quarry for dolomite
                grains. In 2015, we diversified further by launching our talc
                quarry and producing talc powder. Since then, we have
                consistently broadened our product range, adding bentonite, mica
                flakes, mica powder, barite, calcium carbonate powder (calcite),
                kaolin, quartz grains, and quartz powder to our portfolio.
              </p>
              <p>
                Today, we operate 11 manufacturing plants with an impressive
                production capacity of 20,000 metric tons of industrial minerals
                each month, making us one of the largest processors and
                exporters of Indian minerals. Our facilities are strategically
                located near Udaipur, ensuring efficient production and delivery
                of high-quality mineral powders and grains.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <img
                src={story}
                alt="story"
                className="img-fluid"
                style={{ padding: "2em" }}
              />
            </div>
          </div>
        </div>
      </section>
      {/* about-details-end */}

      {/* about-detail-two */}
      <section id="detail-two">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <img
                src={detail}
                alt="detail"
                className="img-fluid"
                style={{ padding: "2em" }}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 para">
              <h4 className="head">Our Value</h4>
              <p>
                At Altecho Ventures, quality is paramount. Our in-house testing
                laboratory guarantees that all materials are rigorously tested
                against a comprehensive set of quality parameters before
                dispatch, ensuring that our customers receive only the best
                products. As one of the largest industrial mineral quarry owners
                in India, we utilize advanced infrastructure, including Raymond
                and ball mills, to enhance our production capabilities.
              </p>
              <p>
                Our products serve diverse industries, including
                pharmaceuticals, rubber, polymers, plastics, masterbatches,
                cosmetics, food and beverages, and ceramics. All chemicals
                manufactured are thoroughly tested and approved by international
                laboratories, including zinc oxide, zinc stearate, calcium
                stearate, potassium stearate, aluminum stearate, calcium zinc
                stabilizer, sodium stearate, and sodium benzoate.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* about-detail-two-end */}

      {/* vision */}
      <section id="vision">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 para">
              <h4 className="head">Our Vision</h4>
              <p>
                Our vision is to establish ourselves as a global leader in the
                industrial minerals and chemicals sector, celebrated for our
                unwavering commitment to quality, innovation, and
                sustainability. We aim to set the benchmark for excellence,
                ensuring that our products not only meet but exceed the
                expectations of our customers. By embracing advanced
                technologies and fostering a culture of continuous improvement,
                we seek to drive progress in the industries we serve, from
                pharmaceuticals to cosmetics.
              </p>
              <p>
                We believe in the power of strong partnerships and community
                engagement. Our goal is to enrich the lives of our customers and
                the communities we operate in by building lasting relationships
                based on trust and transparency. Through responsible practices
                and a focus on sustainable development, we aspire to create
                value for all stakeholders while minimizing our environmental
                impact, ultimately contributing to a healthier planet for future
                generations.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <img
                src={vision}
                alt="vision"
                className="img-fluid"
                style={{ padding: "2em", height: "450px" }}
              />
            </div>
          </div>
        </div>
      </section>
      {/* vision-end */}

      {/* mission */}
      <section id="mission">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 para">
              <h3 class="head">Our Mission</h3>
              <p>
                Our mission is to deliver high-quality industrial minerals and
                chemicals that meet the diverse needs of our customers across
                various sectors. We are dedicated to fostering innovation
                through continuous research and development, ensuring our
                products remain at the forefront of industry standards. By
                implementing sustainable practices throughout our operations, we
                aim to minimize our environmental impact while maximizing
                efficiency. We strive to build strong, transparent relationships
                with our clients and empower our employees, creating a
                collaborative environment that drives growth and contributes
                positively to the communities we serve.
              </p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 center">
              <i
                class="fa fa-cog"
                style={{
                  fontSize: "3em",
                  textAlign: "center",
                  animation: "rotate 2s linear",
                }}
              ></i>
              <h5 class="small-head">Quality and Innovation</h5>
              <p class="small-para">
                Deliver superior minerals and chemicals that exceed customer
                expectations. Invest in research and development to drive
                innovation in our products and processes.
              </p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 center">
              <i
                class="fa fa-industry"
                style={{
                  fontSize: "3em",
                  textAlign: "center",
                  animation: "rotate 2s linear",
                }}
              ></i>
              <h5 class="small-head">Sustainability</h5>
              <p class="small-para">
                Adopt sustainable practices across all operations to minimize
                our environmental impact. Commit to responsible stewardship for
                a healthier planet.
              </p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 center">
              <i
                class="fa fa-handshake-o"
                style={{
                  fontSize: "3em",
                  textAlign: "center",
                  animation: "rotate 2s linear",
                }}
              ></i>
              <h5 class="small-head">Customer Satisfaction</h5>
              <p class="small-para">
                Build lasting partnerships with clients through reliability and
                transparency. Deliver exceptional service to foster trust and
                collaboration.
              </p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 center">
              <i
                class="fa fa-line-chart"
                style={{
                  fontSize: "3em",
                  textAlign: "center",
                  animation: "rotate 2s linear",
                }}
              ></i>
              <h5 class="small-head">Growth and Development</h5>
              <p class="small-para">
                Foster a culture of continuous improvement to empower our
                employees. Contribute to the growth and development of the
                communities we operate in.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* mission-end */}
    </div>
  );
};

export default about;
