import "./App.css";
import React, { useEffect } from "react";
import Navbar from "./components/navbar.js";
import Home from "./pages/home.js";
import Footer from "./components/footer.js";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import About from "./pages/about.js";
import Contact from "./pages/contact.js";
import ProductDetailsPage from "./pages/ProductDetailsPage";
import ChemicalDetailsPage from "./pages/ChemicalDetailsPage";

// ScrollToTop component to scroll to the top on route change
function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [location]);

  return null;
}

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" exact Component={Home} />
          <Route path="/about" exact Component={About} />
          <Route path="/contact" exact Component={Contact} />
          {/* Product Routes */}
          <Route path="/products/:productId" element={<ProductDetailsPage />} />
          {/* Chemical Routes */}
          <Route
            path="/chemicals/:chemicalId"
            element={<ChemicalDetailsPage />}
          />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
