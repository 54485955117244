import React from "react";
import { Link } from "react-router-dom";

function footer() {
  return (
    <div>
      <section id="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h2 className="brand">Alteco</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                aliquet, erat non malesuada consequat, nibh erat tempus risus,
                vitae porttitor purus nisl vitae purus.
              </p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h5 className="ftr-head">Get In Touch</h5>
              <p>
                <i className="fa fa-map-marker"></i>
                710, Fenkin9, Near Satkar Grande, Wagle Estate, Thane West - 400
                604.
              </p>
              <p>
                <i className="fa fa-phone"></i> +91 7738156675
              </p>
              <p>
                <i className="fa fa-envelope"></i> sales@tigiindustries.in
              </p>
              <Link className="social" to="/">
                <i
                  className="fa fa-twitter-square"
                  style={{
                    fontSize: "2em",
                  }}
                ></i>
              </Link>
              <Link className="social" to="/">
                <i
                  className="fa fa-linkedin-square"
                  style={{
                    fontSize: "2em",
                  }}
                ></i>
              </Link>
              <Link className="social" to="/">
                <i
                  className="fa fa-facebook-square"
                  style={{
                    fontSize: "2em",
                  }}
                ></i>
              </Link>
              <Link className="social" to="/">
                <i
                  className="fa fa-instagram"
                  style={{
                    fontSize: "2em",
                  }}
                ></i>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h5 className="ftr-head">Our Products</h5>
              <ul>
                <li>
                  <Link className="footerink" to="/products/silicaRammingMass">
                    <span>&#62;</span> Ramming Mass
                  </Link>
                </li>
                <li>
                  <Link className="footerink" to="/products/calcitePowder">
                    <span>&#62;</span> Calcite Powder
                  </Link>
                </li>
                <li>
                  <Link className="footerink" to="/products/barytesPowder">
                    <span>&#62;</span> Barytes Powder
                  </Link>
                </li>
                <li>
                  <Link className="footerink" to="/products/bentonitePowder">
                    <span>&#62;</span> Bentonite Powder
                  </Link>
                </li>
                <li>
                  <Link className="footerink" to="/products/micaProducts">
                    <span>&#62;</span> Mica Flakes Powder
                  </Link>
                </li>
                <li>
                  <Link className="footerink" to="/products/feldsparPowder">
                    <span>&#62;</span> Feldspar
                  </Link>
                </li>
                <li>
                  <Link className="footerink" to="/products/garnetAbrasiveSand">
                    <span>&#62;</span> Garnet / Abrasive Sand
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h5 className="ftr-head">Popular Links</h5>
              <ul>
                <li>
                  <Link className="footerink" to="./about#about-cont">
                    <span>&#62;</span> About Us
                  </Link>
                </li>
                <li>
                  <Link className="footerink" to="/contact#contact">
                    <span>&#62;</span> Contact Us
                  </Link>
                </li>
                <li>
                  <Link className="footerink" to="">
                    <span>&#62;</span> Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link className="footerink" to="">
                    <span>&#62;</span> Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link className="footerink" to="">
                    <span>&#62;</span> Career
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Footer-rights */}
      <section id="footerrights">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>
                <span>&#169;</span> Copyright <span id="spFY"></span> All rights
                reserved by Alteco Ventures Pvt. Ltd.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* footer-end */}
    </div>
  );
}

export default footer;
