import React from "react";
import { useParams } from "react-router-dom";

const chemicals = {
  zincOxide: {
    name: "Zinc Oxide",
    chemicalComponent: "ZnO",
    description:
      "Zinc Oxide is a versatile, high-performance chemical used in various industries. With its ability to enhance durability, improve strength, and resist UV degradation, it’s particularly valuable in applications such as rubber manufacturing, paints, ceramics, glass production, and cosmetics. Alteco Venture Pvt. Ltd. produces Zinc Oxide of 99.99% and 99.5% purity, ensuring a quality product for global industries.",
    applications:
      "In the rubber industry, Zinc Oxide is essential for vulcanization, increasing rubber durability and heat resistance. It is also widely used in paints for anti-caking, mildew resistance, and corrosion prevention, providing color stability and waterproofing. Additionally, it enhances the strength and color of ceramics, glass, and serves as a UV-blocking agent in cosmetics.",
    packagingOptions:
      "Zinc Oxide is available in flexible packaging formats, including 25 kg and 50 kg bags, tailored to meet diverse client needs. Each package is sealed to ensure long-lasting quality during storage and transportation.",
    other:
      "With a production capacity of 300 metric tons per month, Alteco Venture Pvt. Ltd. supplies Zinc Oxide to markets worldwide, ensuring consistent quality and a reliable supply chain for all clients.",
  },

  zincStearate: {
    name: "Zinc Stearate",
    chemicalComponent: "C₁₈H₃₅ZnO₂",
    description:
      "Zinc Stearate is a premium lubricant and additive, widely used in plastics, polymers, rubber, paints, and cosmetics. This chemical is known for its heat resistance, anti-caking properties, and ability to improve product texture and longevity.",
    applications:
      "In the polymer industry, Zinc Stearate functions as a critical lubricant, reducing friction during processing. It also plays a vital role in plastic and rubber industries as a release agent and stabilizer, enhancing product smoothness and thermal stability. In cosmetics, it is a thickening agent, providing texture and spreadability in formulations.",
    packagingOptions:
      "We offer Zinc Stearate in multiple packaging sizes—25 kg, 50 kg, and jumbo bags—to ensure efficient storage and transport for various industrial needs.",
    other:
      "With a commitment to quality, Alteco Venture Pvt. Ltd. ensures that each batch of Zinc Stearate meets stringent performance standards, making it a trusted choice for industries worldwide.",
  },

  calciumStearate: {
    name: "Calcium Stearate",
    chemicalComponent: "Ca(C₁₈H₃₅O₂)₂",
    description:
      "Calcium Stearate is a versatile, high-quality additive used in plastic manufacturing, waterproofing chemicals, cosmetics, pharmaceuticals, and paper production. Known for its anti-caking and lubricating properties, it supports smooth and efficient processing in a variety of applications.",
    applications:
      "In plastics, it serves as a release agent, enhancing molding processes. Its waterproofing properties are utilized in coatings and sealants, while in cosmetics, it thickens and stabilizes formulations. Additionally, it acts as a lubricant in the paper industry, improving product durability.",
    packagingOptions:
      "Available in 25 kg, 50 kg, and 1-ton jumbo bags, our packaging is designed for safe handling and easy transport, accommodating small and large-scale requirements.",
    other:
      "Alteco Venture Pvt. Ltd. is a trusted supplier of Calcium Stearate worldwide, providing a product that consistently meets international standards for purity and effectiveness.",
  },

  aluminiumStearate: {
    name: "Aluminium Stearate",
    chemicalComponent: "C₃₆H₇₀AlO₄",
    description:
      "Aluminium Stearate is a high-performance lubricant and anti-caking agent used in plastics, paints, inks, grease, and cosmetics. It provides excellent lubricity and flow, enhancing the quality and efficiency of various production processes.",
    applications:
      "In the plastic industry, Aluminium Stearate ensures smooth flow and reduces friction. It is also essential in paints and varnishes, improving viscosity and pigment dispersion. For inks, it prevents clumping, ensuring consistent quality, and in cosmetics, it acts as a stabilizer to extend shelf life.",
    packagingOptions:
      "Available in 25 kg, 50 kg, and 1-ton jumbo bags, packaging options include paper, liner, laminated, and unlaminated bags for flexibility in transport and storage.",
    other:
      "Alteco Venture Pvt. Ltd. prides itself on the quality and reliability of its Aluminium Stearate, a product designed to meet high industrial standards worldwide.",
  },

  ferrousSulphate: {
    name: "Ferrous Sulphate",
    chemicalComponent: "FeSO₄",
    description:
      "Ferrous Sulphate is essential for agricultural health, particularly for improving plant growth and soil fertility. With 19% iron content, it provides the necessary nutrients for chlorophyll production, ensuring optimal crop productivity.",
    applications:
      "Used in agriculture, Ferrous Sulphate corrects iron deficiencies, preventing issues like leaf yellowing and stunted growth. It’s also applied in soil treatments to improve plant health, contributing to better yields and stronger plants.",
    packagingOptions:
      "Ferrous Sulphate is offered in 5 kg, 10 kg, 25 kg, and 50 kg bags, tailored to meet diverse farming needs and support easy application and storage.",
    other:
      "Alteco Venture Pvt. Ltd. supplies Ferrous Sulphate globally, meeting strict agricultural standards to support farmers in enhancing crop health and productivity.",
  },

  zincSulphate: {
    name: "Zinc Sulphate",
    chemicalComponent: "ZnSO₄",
    description:
      "Zinc Sulphate is a vital agricultural additive, improving soil fertility and boosting plant growth. Available in 21% and 33% grades, it’s instrumental in ensuring plants receive the essential zinc required for robust growth.",
    applications:
      "Zinc Sulphate enhances chlorophyll production and overall plant health, supporting higher crop yields and disease resistance. It can be applied as a soil additive or foliar spray to address nutrient deficiencies effectively.",
    packagingOptions:
      "Available in 5 kg, 10 kg, 25 kg, and 50 kg bags, with custom packaging options for optimal storage and handling convenience.",
    other:
      "As a trusted supplier, Alteco Venture Pvt. Ltd. ensures that each batch of Zinc Sulphate meets agricultural quality standards, supporting sustainable farming practices worldwide.",
  },

  magnesiumStearate: {
    name: "Magnesium Stearate",
    chemicalComponent: "Mg(C₁₇H₃₅COO)₂",
    description:
      "Magnesium Stearate is a multifunctional additive widely used in pharmaceuticals, cosmetics, food, and plastics. Known for its lubricating and anti-caking properties, it enhances production efficiency and product consistency.",
    applications:
      "In pharmaceuticals, it prevents ingredients from sticking to machinery, ensuring smooth tablet and capsule production. It also serves as an anti-caking agent in food and cosmetics, maintaining product texture and extending shelf life.",
    packagingOptions:
      "We provide Magnesium Stearate in 25 kg, 50 kg, and 1-ton jumbo bags, with custom packaging available to meet diverse industry needs.",
    other:
      "Alteco Venture Pvt. Ltd. is a leading exporter of Magnesium Stearate, committed to delivering high-quality products to global markets with a focus on reliability and performance.",
  },

  calciumZincStabilizer: {
    name: "Calcium Zinc Stabilizer",
    chemicalComponent: "Ca-Zn (Combination of Calcium and Zinc compounds)",
    description:
      "Calcium Zinc Stabilizer is a critical stabilizer used in PVC, plastic, and medical industries. It is an environmentally friendly alternative to traditional stabilizers, providing excellent heat resistance and stability.",
    applications:
      "In PVC production, it helps maintain product integrity under high temperatures. It is also widely used in food packaging, wires, and medical products to enhance durability and heat resistance.",
    packagingOptions:
      "Available in 25 kg, 50 kg, and 1-ton jumbo bags, with custom options to accommodate various industrial requirements.",
    other:
      "With a strong commitment to quality, Alteco Venture Pvt. Ltd. supplies Calcium Zinc Stabilizers to international markets, ensuring consistent and reliable performance.",
  },

  potassiumStearate: {
    name: "Potassium Stearate",
    chemicalComponent: "C₁₈H₃₅KO₂",
    description:
      "Potassium Stearate is an effective emulsifier, lubricant, and thickening agent used in cosmetics, food, rubber, and textiles. Its water solubility and versatility make it a valuable additive across industries.",
    applications:
      "It serves as a stabilizer in cosmetics, a cleaning agent in food processing, a release agent in rubber manufacturing, and a softener in textiles, enhancing product texture and quality.",
    packagingOptions:
      "Potassium Stearate is available in 25 kg, 50 kg, and 1-ton jumbo bags, with custom packaging for ease of transport and storage.",
    other:
      "Alteco Venture Pvt. Ltd. delivers Potassium Stearate of the highest standards, supporting clients across diverse sectors worldwide.",
  },

  sodiumStearate: {
    name: "Sodium Stearate",
    chemicalComponent: "C₁₈H₃₅NaO₂",
    description:
      "Sodium Stearate is a key ingredient in soap, cosmetics, and food, serving as a binder, stabilizer, and emulsifier. It enhances product texture, consistency, and stability in various applications.",
    applications:
      "In soaps, it creates a creamy lather and improves viscosity. In cosmetics, it acts as a stabilizer, while in food, it prevents clumping, ensuring product uniformity.",
    packagingOptions:
      "We offer Sodium Stearate in 25 kg, 50 kg, and 1-ton jumbo bags, with options for liner and non-liner packaging to suit specific customer needs.",
    other:
      "Alteco Venture Pvt. Ltd. ensures quality in every batch of Sodium Stearate, making it a reliable choice for industries globally.",
  },

  sodiumBenzoate: {
    name: "Sodium Benzoate",
    chemicalComponent: "C₇H₅NaO₂",
    description:
      "Sodium Benzoate is a powerful preservative used in food, cosmetics, and pharmaceuticals. Known for its antimicrobial properties, it extends the shelf life of various products by preventing spoilage.",
    applications:
      "It is widely used in the food industry to preserve soft drinks, fruit juices, and pickles. In cosmetics, it helps maintain product integrity, and in pharmaceuticals, it preserves liquid medicines.",
    packagingOptions:
      "Sodium Benzoate is available in 25 kg, 50 kg, and 1-ton jumbo bags, with liner and non-liner packaging options for flexibility in storage and transport.",
    other:
      "Alteco Venture Pvt. Ltd. supplies Sodium Benzoate worldwide, meeting the highest quality standards for consistent product safety and effectiveness.",
  },

  // Add more chemical data here
};

function ChemicalDetailsPage() {
  const { chemicalId } = useParams();
  const chemical = chemicals[chemicalId];

  if (!chemical) {
    return <p>Chemical not found.</p>;
  }

  return (
    // <div>
    //   <h1>{chemical.name}</h1>
    //   <p>{chemical.description}</p>
    //   <h3>Applications:</h3>
    //   <ul>
    //     {chemical.applications.map((app, idx) => (
    //       <li key={idx}>{app}</li>
    //     ))}
    //   </ul>
    //   <h3>Packaging Options:</h3>
    //   <ul>
    //     {chemical.packagingOptions.map((option, idx) => (
    //       <li key={idx}>{option}</li>
    //     ))}
    //   </ul>
    //   <p>{chemical.other}</p>
    // </div>
    <div className="container">
      <div className="row chemical-details">
        <h1 className="title">{chemical.name}</h1>
        <p>{chemical.description}</p>
        <h4 className="subtitle">Chemical Component:</h4>
        <p>{chemical.chemicalComponent}</p>
        <h4 className="subtitle">Applications:</h4>
        <p>{chemical.applications}</p>
        <h4 className="subtitle">Packaging Options:</h4>
        <p>{chemical.packagingOptions}</p>
        <p>{chemical.other}</p>
      </div>
    </div>
  );
}

export default ChemicalDetailsPage;
