import React from "react";
import homeimage from "../assets/imgs/labor-working-at-coal-mining-site-4981025-4182899.webp";
import aboutimage from "../assets/imgs/coal-mining-5590139-4677814.webp";
import sectorimage from "../assets/imgs/trans.png";
import roleimage from "../assets/imgs/mineria-vecteezy.png";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll"; // import react-scroll Link

function home() {
  return (
    <div>
      {/* main-intro */}
      <section id="home">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 one">
              <h3 className="main">
                Empowering Innovation with Pure
                <span className="main-head">Minerals</span> and Precision
                <span className="main-head">chemicals</span>
              </h3>
              <p className="main-para">
                we specialize in transforming nature's finest minerals and
                chemicals into innovative solutions tailored to meet your needs.
                With a commitment to quality and sustainability, our products
                empower industries ranging from manufacturing to technology.
                <br />
                <ScrollLink
                  to="about"
                  smooth={true}
                  duration={500}
                  className="main-knw"
                >
                  Know More
                </ScrollLink>
                <Link to="/contact" className="main-cnt">
                  Contact Us
                </Link>
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 two">
              <img className="img-fluid" src={homeimage} alt="home" />
            </div>
          </div>
        </div>
      </section>
      {/* main-intro-end */}

      {/* about */}
      <section id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <img className="img-fluid" src={aboutimage} alt="about" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="abt-para">
                <p className="abt-head-para">
                  Your Premier Manufacturer of
                  <span className="abt-head">Minerals</span> and
                  <span className="abt-head">Chemicals</span>
                </p>
                <p>
                  Welcome to Altecho Ventures Pvt. Ltd.! With over a decade of
                  expertise, we're your go-to source for high-quality minerals
                  and chemicals in Udaipur, Rajasthan. Our extensive range
                  includes dolomite, talc, quartz, feldspar, and garnet,
                  alongside a variety of chemicals like zinc oxide and
                  stearates, all rigorously tested to meet the highest
                  standards. We supply 20,000 metric tons of industrial raw
                  materials monthly, serving industries from paints to steel
                  manufacturing. Committed to quality and customer satisfaction,
                  we're not just leaders in India but also proud exporters
                  worldwide. Le's elevate your business together!
                </p>
                <div>
                  <Link className="abt-btn" to="/about">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about-end */}

      {/* sector */}
      <section id="sector">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h4 className="sect-tag">
                Elevating Industries with Quality Minerals and Chemicals —
                <span className="sect-head">Globally Trusted!</span>
              </h4>
              <p className="sect-para">
                Our state-of-the-art facilities, featuring 11 advanced plants,
                enable us to supply an impressive 20,000 metric tons of
                industrial raw materials every month. We are deeply committed to
                quality; each batch of our minerals undergoes rigorous testing
                in our in-house laboratory to ensure compliance with the highest
                industry standards. Serving a diverse range of sectors—including
                paints and coatings, glass manufacturing, plastics, cattle feed,
                ceramic tiles, soap and detergents, steel and iron production,
                and oil drilling. We proudly position ourselves as a leading
                supplier in India and a trusted exporter globally. With a robust
                inventory of at least 10,000 metric tons readily available, and
                as the largest quarry owner of dolomite and talc, we are
                dedicated to achieving 100% customer retention through
                exceptional quality and timely delivery.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="sect-img">
                <img src={sectorimage} className="img-fluid" alt="sectore" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* sector-end */}

      {/* role */}
      <section id="role">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="role-img">
                <img src={roleimage} className="img-fluid" alt="role" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h4 className="role-tag">
                <span className="role-head">Driving Progress</span> with
                Exceptional Raw Materials
              </h4>
              <p>
                Minerals and chemicals are fundamental to numerous industries,
                serving as essential raw materials that drive innovation and
                efficiency. At Altecho Ventures Pvt. Ltd., we play a crucial
                role in this landscape by providing high-quality minerals like
                dolomite and talc, which enhance the durability and
                functionality of products across sectors such as paints,
                coatings, ceramics, and plastics. Our commitment to rigorous
                testing and quality assurance ensures that our offerings meet
                the highest industry standards, giving our clients confidence in
                their production processes. Additionally, our extensive range of
                specialized chemicals is designed to optimize performance and
                compliance, enabling businesses to adapt to evolving market
                demands. By choosing Altecho Ventures, you partner with a leader
                dedicated to delivering superior materials that empower your
                business to achieve a competitive edge and sustain growth in a
                dynamic environment.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* role-end */}
    </div>
  );
}

export default home;
